import EventBus from "eventing-bus";
import { connect } from 'react-redux';
import Countdown from 'react-countdown';
import OwlCarousel from 'react-owl-carousel';
import { HashLink } from 'react-router-hash-link';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import './index.css';
import '../../static/css/animate.css';
import 'owl.carousel/dist/assets/owl.carousel.css';

import { web3 } from "../../store/web3";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';
import { networkId, message, opensea } from "../../store/config";
import { toggleLoader } from "../../store/actions/Auth";
import { Token, TokenAddress, ICO, ICOAddress } from '../../store/contract/index';


class KitFItz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sticky: false,
            isNetwork: true,
            isPreSale: false,
            rate: '',
            nftPrice: '',
            quantity: 0,
            max: '',
            min: 0,
            mintItems: '',
            availableItems: '',
            contributions: '',
            tokenID: [],
            openingTime: 1636526040,
            closingTime: '',
            isMintedModal: false,
            address: localStorage.getItem('publicAddress'),
        };
    };

    async componentDidMount() {
        this.checkEthereum();
    };

    checkEthereum = async () => {
        let { address } = this.state;
        if (typeof window.ethereum !== "undefined") {
            // check network
            web3.eth.net.getId((err, netId) => {
                if (netId != networkId) this.setState({ isNetwork: false });
                else this.setState({ isNetwork: true });
            });
            let rate = await (ICO.methods.rate().call());
            let cap = await (ICO.methods.cap().call());
            let minted = await (ICO.methods.minted().call());
            let maximum = await (ICO.methods.investorHardCap().call());
            let openingTime = await (ICO.methods.openingTime().call());
            let closingTime = await (ICO.methods.closingTime().call());
            let nftPrice = await (web3.utils.fromWei(rate.toString(), 'ether'));

            let availableItems = parseFloat(cap);
            let mintItems = parseFloat(minted);
            let max = parseFloat(maximum);
            if (address !== '' && address !== null && address !== undefined) {
                let tokenID = await (Token.methods.walletOfOwner(address).call());
                let contribution = await (ICO.methods.contributions(address).call());
                let contributions = parseFloat(contribution);
                this.setState({ tokenID, contributions });
            };
            this.setState({ rate, nftPrice, max, openingTime, closingTime, availableItems, mintItems });
        } else this.setState({ metamaskNull: true });
    };

    componentWillReceiveProps({ publicAddress }) {
        this.setState({ address: publicAddress });
    };

    increaseQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(quantity) + parseFloat(contributions));
        if (limit < max) {
            quantity = parseFloat(quantity) + 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't exceed quantity then maximum limit of ${max}`);
    };

    decreaseQuantity = () => {
        let { address, quantity, min } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        if (quantity > min) {
            quantity = parseFloat(quantity) - 1;
            this.setState({ quantity });
        }
        else EventBus.publish('info', `You can't decrease quantity to minimum`);
    };

    maxQuantity = async () => {
        let { address, quantity, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let limit = await (parseFloat(max) - parseFloat(contributions));
        if (quantity < limit) {
            let quantity = parseFloat(max) - parseFloat(contributions);
            this.setState({ quantity })
        }
        else EventBus.publish('info', `You already have maximum limit of quantity`);
    };

    handleQuantity = (number) => {
        let { address, quantity, min, max, contributions } = this.state;
        if (address == '' || address == null) {
            EventBus.publish('error', `Connect to Metamask`);
            return;
        };
        let value = parseFloat(number);
        if (quantity > 0) this.setState({ quantity: 0 });
        if (value > min && value <= max) {
            let limit = (parseFloat(quantity) + parseFloat(contributions));
            if (limit < max) {
                this.setState({ quantity: value });
            }
        }
        else {
            if (value > max) EventBus.publish('info', `You are exceeding max amount of ${max}`);
        }
    };

    mintNFT = async () => {
        try {
            let { rate, quantity, address, availableItems } = this.state;

            if (address == '' || address == null) {
                EventBus.publish('error', `Connect to Metamask`);
                return;
            };
            if (!validate(address, 'ETH')) {
                EventBus.publish('error', `Please provide valid ETH Address`);
                return;
            };
            if (quantity == 0) {
                EventBus.publish('error', `Please Enter the Quantity`);
                return;
            };

            const value = rate * quantity;
            const from = (await web3.currentProvider.enable())[0];
            const balance = await web3.eth.getBalance(from);
            const mintItems = await (ICO.methods.minted().call());

            if (mintItems >= availableItems) {
                EventBus.publish('error', `Sale has been Ended`);
                return;
            };

            if (value > balance) {
                EventBus.publish('error', `Your have insufficient ETH Balance`);
                return;
            };

            /** Mint NFT **/
            this.props.toggleLoader({ message: 'Minting in Progress...', status: true });
            await web3.eth.sendTransaction({
                from,
                value,
                to: ICOAddress,
                data: ICO.methods.buyNFT(address, quantity).encodeABI(),
            }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
                .on('receipt', async (receipt) => {
                    const tokenID = await (Token.methods.walletOfOwner(address).call());
                    const mintItems = await (ICO.methods.minted().call());
                    const contributions = await (ICO.methods.contributions(address).call());

                    this.setState({ tokenID, mintItems, contributions, quantity: 0 });
                    this.props.toggleLoader({ status: false });
                    this.toggleMintedModal();
                    EventBus.publish('success', `NFT Minted successfully`);
                })
        } catch (e) {
            console.log('********Error = ', e);
            this.props.toggleLoader({ message: 'Minting not Completed...', status: false });
            EventBus.publish('error', `Unable to Mint NFT`);
        };
    };

    handleReload = () => {
        this.setState({ isPreSale: true });
        EventBus.publish('info', "Sale is Started");
    };

    toggleMintedModal = () => this.setState({ isMintedModal: !this.state.isMintedModal });

    renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            this.setState({ isPreSale: true });
            return <h2 className="">Sale is Started</h2>;
        }
        else {
            return (
                <div className="countdown-timer-time">
                    <div className="counter-day counter-days">
                        <h5>{days ? days : '-'}</h5>
                        <p>Days</p>
                    </div>
                    <div className="counter-day counter-hour">
                        <h5>{hours ? hours : '-'}</h5>
                        <p>Hrs</p>
                    </div>
                    <div className="counter-day counter-minute">
                        <h5>{minutes ? minutes : '-'}</h5>
                        <p>Mins</p>
                    </div>
                    <div className="counter-day counter-seconds">
                        <h5>{seconds ? seconds : '-'}</h5>
                        <p>Sec</p>
                    </div>
                </div>
            );
        }
    };

    onScroll = () => {
        const { pageYOffset } = window;
        if (pageYOffset > 20) this.setState({ sticky: true });
        if (pageYOffset < 20) this.setState({ sticky: false });
    };

    render() {
        const owl_option = {
            margin: 0,
            nav: true,
            items: 4,
            loop: true,
            autoplay: true,
            stagePadding: 150,
            responsive: {
                0: {
                    items: 1,
                },
                400: {
                    items: 1,
                },
                600: {
                    items: 2,
                },
                700: {
                    items: 3,
                },
                1000: {
                    items: 4,
                }
            },
        };

        let { isNetwork, isPreSale, quantity, nftPrice, mintItems, availableItems, openingTime,
            isMintedModal, tokenID, metamaskNull, max, contributions, sticky } = this.state;

        return (
            <div className="kitfitz-mint" onWheel={this.onScroll}>
                <Navbar sticky={sticky} />
                {/* Banner Section */}
                <section id="home" className="banner-sec">
                    <div className="container-fluid">
                        {/* <div className="row">
                            <div className="banner-text col-lg-12 col-md-12">
                                <ul>
                                    <li><button onClick={this.connectWallet} className="btn-style-one" type="button"> <i><img src={require('../../static/images/landing/wallet.png')} alt='' /></i>
                                        {address == '' || address == null
                                            ? 'Connect'
                                            : address && address.substring(0, 5) + '.....' + address.substring(37, address.length)
                                        }
                                    </button>
                                    </li>
                                    <li> <HashLink smooth to="/#about" >Story</HashLink></li>
                                    <li> <HashLink smooth to="/#mint" >Minting</HashLink></li>
                                    <li> <HashLink smooth to="/#shot-gallery" >Gallery</HashLink></li>
                                    <li> <HashLink smooth to="/#roadmap" >Roadmap</HashLink></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div className="home-bg"><img src={require('../../static/images/landing/banner-bg.png')} alt='' /></div>
                    <div className="home-bg-mobile"><img src={require('../../static/images/landing/banner-mobile.png')} alt='' /></div>

                </section>
                {/* About Section */}
                <section className="about-sec" id="about" style={{ backgroundImage: `url(${require("../../static/images/landing/stroy-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="about-text col-lg-6 col-md-12">
                                <div className="about-inner">
                                    <div className="sec-title">
                                        <h2>The Story</h2>
                                        <p style={{ backgroundImage: `url(${require("../../static/images/landing/strory-text-bg.png")})` }}>Hidden in plain sight, KITs are taking over the world by adopting human behaviour. KITs exist online only, bound by the beginning of the Internet Jan 1st 1983. What they don’t know is they are currently in the Retro Era, but are evolving fast. <br />Their goal is to learn human behaviour, blend in with them and eventually: world domination. Meeting and creating other KITs is essential. From around the world they meet at The KIT Fitz Inn - a secret lodge Exclusive to KITs, where they hatch their plans and hang out.</p>
                                        <a className="#" className="btn-style-one"><i><img src={require('../../static/images/landing/icon1.png')} alt='' /></i>join discord</a>
                                    </div>
                                </div>
                            </div>
                            <div className="about-img col-lg-6 col-md-12">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/about-img.png')} alt='' />
                                </div>
                            </div>
                        </div>

                        <div className="row about-box style-two">
                            <div className="about-img col-lg-6 col-md-12">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/about-img2.png')} alt='' />
                                </div>
                            </div>
                            <div className="about-text col-lg-6 col-md-12">
                                <div className="about-inner">
                                    <div className="sec-title">
                                        <p style={{ backgroundImage: `url(${require("../../static/images/landing/strory-text-bg.png")})` }}>They even upload wigs, clothes, accessories in an attempt to fool humans and other computer systems online and offline. <br />Little do they know that  ‘The KIT Fitz Inn’ was built by a human team as a ruse to attract KITs and keep an eye on them, slowing global domination and singularity. The grooviness of the retro human era has led some of these KIT’s into useless bliss, procrastination and joy. </p>
                                        <HashLink smooth to="/#mint" className="btn-style-one"><i><img src={require('../../static/images/landing/icon1.png')} alt='' /></i>MINT NOW</HashLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>
                {/* Project Section */}
                <section className="project-sec" id="project" style={{ backgroundImage: `url(${require("../../static/images/landing/project-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="project-text col-lg-12 col-md-12">
                                <div className="project-inner">
                                    <div className="sec-title">
                                        <h2>THE KIT FITZ INN</h2>
                                        <p>This project is designed for collecting KIT’s to keep the enemy close. Once a KIT is bought they focus on being your friend, forgetting their mission. In turn they fix and build better tools that profit you as a thanks for letting them fit in.</p>
                                        <p>#KeepInTouch #KIT</p>

                                        <div className="btn-area text-center">
                                            <a href="#" className="btn-style-one"><i><img src={require('../../static/images/landing/icon1.png')} alt='' /></i>join discord</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Minting Section */}
                {/* <section id="mint" className="minting-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    {isNetwork && !metamaskNull && availableItems !== '' &&
                                        < div className="sec-title text-center">
                                            {isPreSale
                                                ? <Fragment>
                                                    {mintItems < availableItems
                                                        ? <h2>Minting</h2>
                                                        : <h2>Minted</h2>
                                                    }
                                                </Fragment>
                                                : <h2>Launch In</h2>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                            {isNetwork && !metamaskNull
                                ? <div className={`minting-form ${isPreSale ? 'col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12' : 'col-lg-12'}`}>
                                    {isPreSale
                                        ? <Fragment>
                                            {availableItems !== ''
                                                ? <Fragment>
                                                    {mintItems < availableItems
                                                        ? <div className="minting-inner">
                                                            <form className="minting">
                                                                {contributions == max
                                                                    ? <div className="countdown-timer">
                                                                        <h4 className="">You have minted maximum quantity of NFT's</h4>
                                                                        <br />
                                                                        {tokenID.length > 0 &&
                                                                            <div className="view-minted-btn">
                                                                                <button onClick={this.toggleMintedModal} className="btn-style-one minted-btn" type="button">View Your NFT's</button>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    : < Fragment>
                                                                        <div className="minting-data">
                                                                            <p>Total availabe NFT’s: {availableItems - mintItems}</p>
                                                                            <p>Total minted NFT’s: {mintItems}</p>
                                                                        </div>
                                                                        <div className="group-form">
                                                                            <input type="text" name="price" value={`Price per NFT: ${nftPrice} ETH`} disabled />
                                                                        </div>
                                                                        <div className="group-form number-form">
                                                                            <input type="number" name="number" onChange={(e) => this.handleQuantity(e.target.value)} value={quantity > 0 && quantity} placeholder="Enter quantity" />
                                                                            <button onClick={this.decreaseQuantity} type="button" className="count-btn minus-btn"> <i className="fa fa-minus"></i> </button>
                                                                            <button onClick={this.increaseQuantity} type="button" className="count-btn plus-btn"> <i className="fa fa-plus"></i> </button>
                                                                            <button onClick={this.maxQuantity} type="button" className="max-btn">Max</button>
                                                                        </div>
                                                                        <div className="group-form">
                                                                            <input type="text" name="Tprice" value={`Total price: ${(nftPrice * quantity).toFixed(3)} ETH`} disabled />
                                                                        </div>
                                                                        <div className="group-form">
                                                                            <button onClick={this.mintNFT} className="btn-style-one" type="button">MINT NOW</button>
                                                                        </div>
                                                                        {tokenID.length > 0 &&
                                                                            <div className="view-minted-btn">
                                                                                <button onClick={this.toggleMintedModal} className="btn-style-one minted-btn" type="button">View Your NFT's</button>
                                                                            </div>
                                                                        }
                                                                    </Fragment>
                                                                }
                                                            </form>
                                                        </div>
                                                        : <div className="countdown-timer">
                                                            <h2 className="">NFT’s Sold!!!</h2>
                                                            <br />
                                                            <a href={`${opensea}collection/`} target="_blank" className="btn-style-one">View on OpenSea</a>
                                                        </div>
                                                    }
                                                </Fragment>
                                                : <div className="countdown-timer">
                                                    <h2 className="loader-text">Loading!!!</h2>
                                                    <br />
                                                    <i className="fa fa-spinner fa-pulse loader-mint" aria-hidden="true" />
                                                </div>
                                            }
                                        </Fragment>
                                        : <div className="countdown-timer">
                                            <div className="timer-inner">
                                                {openingTime &&
                                                    <Countdown date={Date.now() + parseInt((openingTime * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                                }
                                                <Countdown date={Date.now() + parseInt((1642248000 * 1000) - Date.now())} renderer={this.renderer} onComplete={this.handleReload} />
                                            </div>
                                        </div>
                                    }
                                </div>
                                : <div className="minting-form col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-sm-12">
                                    <div className="countdown-timer">
                                        <h2 className="">{metamaskNull ? 'Please install metamask extension!' : message}</h2>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section> */}
                {/* NFT's Section */}
                {/* <section id="nft" className="nft-sec" style={{ backgroundImage: `url(${require("../../static/images/landing/banner-bg.png")})` }}>
                    <div className="container-fluid">
                        <div className="row">
                            <OwlCarousel className="slider-items owl-carousel ltf-owl owl-theme" autoplaySpeed={3000}  {...owl_option} nav>
                                <div className="item">
                                    <div className="nft-img-box">
                                        <img src={require('../../static/images/landing/nft-1.png')} alt='' />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="nft-img-box">
                                        <img src={require('../../static/images/landing/nft-2.png')} alt='' />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="nft-img-box">
                                        <img src={require('../../static/images/landing/nft-3.png')} alt='' />
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="nft-img-box">
                                        <img src={require('../../static/images/landing/nft-4.png')} alt='' />
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="nft-img-box">
                                        <img src={require('../../static/images/landing/nft-5.png')} alt='' />
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                    </div>
                </section> */}



                {/* Shot Gallery Section */}
                <section id="shot-gallery" className="shot-gallery" style={{ backgroundImage: `url(${require("../../static/images/landing/shot-gallery-bg.png")})` }}>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className="sec-title">
                                    <h2>Smug shot gallery</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-1.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-2.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-3.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-4.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-5.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-6.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner block-7">
                                    <img src={require('../../static/images/landing/gallery-7.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-8.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner block-7">
                                    <img src={require('../../static/images/landing/gallery-9.png')} alt='' />
                                </div>
                            </div>
                            <div className="shot-gallery-img col">
                                <div className="img-inner">
                                    <img src={require('../../static/images/landing/gallery-10.png')} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Project Section */}
                    {/* <div className="project-sec">
                        <div className="auto-container">
                            <div className="row">
                                <div className="project-text col-lg-12 col-md-12">
                                    <div className="project-inner">
                                        <div className="sec-title">
                                            <h2>How can you help</h2>
                                            <p>The KIT’s have been told that they will be integrated to humans via the block chain. Each of them has posed for a ‘smug shot’ with the hope they will be selected for a mission to keep in touch with you and become as human as possible. You can select your KIT and help befriend it to interrupt their goal.</p>
                                            <p>Some of the KIT’s are not fully connected to the internet, They are lost radicals in the metaverse attempting to grab other hosts like radios and MRI<br />machines. You can short circuit this radical into a KIT by<br />1. crying for an hour solid into your keyboard<br />2. click ‘short circuit’ to fast track the connection making the KIT your friend.</p>

                                            <div className="btn-area text-center">
                                                <a href="#" className="btn-style-one"><i><img src={require('../../static/images/landing/icon1.png')} alt='' /></i>join discord</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </section>


                {/* Roadmap Section */}
                <section id="roadmap" className="roadmap" >
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title">
                                    <h2>Roadmap</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="timeline">
                                    <div className="top-img"></div>
                                    <div className="timeline-container left active">

                                        <div className="content">
                                            {/* <h5>stage 1</h5> */}
                                            <h2>stage 1</h2>
                                            <p>Develop and launch the community Discord server. Exclusive roles and channels to KIT owners.</p>
                                        </div>
                                    </div>
                                    <div className="timeline-container right">

                                        <div className="content">
                                            {/* <h5>stage 2</h5> */}
                                            <h2>stage 2</h2>
                                            <p>Whitelisted Presale, allowing special access for the community to mint. Continue marketing post launch, and hold giveaways, collaborations and contests!</p>
                                        </div>
                                    </div>
                                    <div className="timeline-container left">

                                        <div className="content">
                                            {/* <div className="img-car"><img src={require('../../static/images/landing/car.png')} alt='' /></div> */}
                                            {/* <h5>stage 3</h5> */}
                                            <h2>stage 3</h2>
                                            <p>Build the Kit Fitz Inn Metaverse edition! <br />Exclusive Kit Fitz Inn Merch Drop</p>
                                        </div>
                                    </div>
                                    <div className="timeline-container right">

                                        <div className="content">
                                            {/* <h5>stage 4</h5> */}
                                            <h2>stage 4</h2>
                                            <p>Companion NFT drop</p>
                                        </div>
                                    </div>
                                    <div className="timeline-container left">

                                        <div className="content">
                                            {/* <h5>stage 5</h5> */}
                                            <h2>stage 5</h2>
                                            <p>The DAO</p>
                                        </div>
                                    </div>

                                    <div className="timeline-container right">

                                        <div className="content">
                                            {/* <h5>stage 4</h5> */}
                                            <h2>stage 6</h2>
                                            <p>Build The Kit Fitz Inn, Real Life Edition!!!</p>
                                        </div>
                                    </div>
                                    <div className="timeline-container left">

                                        <div className="content">
                                            {/* <h5>stage 5</h5> */}
                                            <h2>stage 7</h2>
                                            <p>Throughout the lifetime of the project, we will be continuing marketing this collection.</p>
                                        </div>
                                    </div>


                                    <div className="bottom-img"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* FAQ's Section */}
                <section id="faqs" className="faqs-sec">
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>FAQs</h2>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div id="accordion">
                                    <div class="card">
                                        <div class="card-header" id="headingOne">
                                            <h5 class="mb-0">
                                                <button class="red btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                    What is the total supply?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                            <div class="card-body">
                                                10,000
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingTwo">
                                            <h5 class="mb-0">
                                                <button class="green btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    How much per mint?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                            <div class="card-body green">
                                                0.05 per NFT
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card">
                                        <div class="card-header" id="headingThree">
                                            <h5 class="mb-0">
                                                <button class="blue btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Wen is mint?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                            <div class="card-body">
                                                Minting is through whitelist only. If you wish to get on the whitelist, please head to our discord and learn how you can get onto it before mint. If you are not able to get onto the whitelist, you will be able to buy from Opensea once the KITs begin minting.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFour">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Wen Launch?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                            <div class="card-body">
                                                Please check our Discord and Twitter for announcements on when launch will be.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card">
                                        <div class="card-header" id="headingFive">
                                            <h5 class="mb-0">
                                                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    I want to partner with The Kit Fitz Inn, how do I go about doing this?
                                                </button>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                            <div class="card-body">
                                                We are always open to hearing ideas, please contact the team on discord and we can go from there. <br />
                                                Admins and team members will NEVER DM you first.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Team Section */}
                <section id="team" className="team-sec">
                    {/* <div className="team-header"><img src={require('../../static/images/landing/team-header.png')} alt='' /></div> */}
                    <div className="auto-container">
                        <div className="row">
                            <div className="col-12">
                                <div className="sec-title text-center">
                                    <h2>Team</h2>
                                    <p>Here’s the human team who built the The Kit Fitz Inn and work undercover as the following characters to help find KITs friends. </p>
                                </div>
                            </div>

                            <div className="team-block col-lg-3 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-4.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>R3D-7 </h4>
                                        <p>Architect</p>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-3 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-2.png')} alt='' />
                                    </div>
                                    <div className="textbox-team style-two">
                                        <h4>AX13</h4>
                                        <p>Developer</p>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-3 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-3.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>KZR5</h4>
                                        <p>Developer</p>
                                    </div>
                                </div>
                            </div>

                            <div className="team-block col-lg-3 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-1.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>ULTRAMAN</h4>
                                        <p>Droid Wrangler</p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="team-block col-lg-3 col-md-6 col-sm-12">
                                <div className="team-inner">
                                    <div className="image-box">
                                        <img src={require('../../static/images/landing/team-5.png')} alt='' />
                                    </div>
                                    <div className="textbox-team">
                                        <h4>ULTRAMAN</h4>
                                        <p>Developer</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                    </div>
                </section>


                <Footer />

                {/* ---------------VIEW MINTED MODAL--------------- */}

                <Modal isOpen={isMintedModal} toggle={this.toggleMintedModal} className="main-modal view-mint-modal">
                    <ModalHeader toggle={this.toggleMintedModal}></ModalHeader>
                    <ModalBody className="modal-body">
                        <div className="row d-flex justify-content-center">
                            <h4 className="">View on OpenSea</h4>
                        </div>
                        <div className="row py-5 d-flex justify-content-between">
                            {tokenID.map(data => {
                                return (
                                    <Fragment>
                                        <div className="col-8 d-flex justify-content-center align-items-center">
                                            <h5 className="">Token ID# {data}</h5>
                                        </div>
                                        <div className="col-4 d-flex justify-content-center">
                                            <a className="btn-style-one mb-3" target="_blank" href={`${opensea}assets/${TokenAddress}/${data}`}>View</a>
                                        </div>
                                    </Fragment>
                                )
                            })
                            }
                        </div>
                    </ModalBody>
                </Modal>

            </div >
        );
    }
}

const mapDispatchToProps = { toggleLoader };

const mapStateToProps = ({ Auth }) => {
    let { publicAddress } = Auth;
    return { publicAddress }
};

export default connect(mapStateToProps, mapDispatchToProps)(KitFItz);