
/* -- set app title --*/
const AppTitle = 'FRONTEND KIT FITZ';

/* -- set app mode -- */
// const AppMode = [''];
const AppMode = ['production'];

/* -- set API URLs --*/
const testing = 'https://dserver.kitfitz.io';
const production = 'https://server.kitfitz.io';
const development = 'https://dserver.kitfitz.io';

let SocketUrl, opensea;
let env = AppMode[0] || 'development', networkId = '', message = '', explorer = '';

switch (AppMode[0]) {
  case 'development':
    networkId = 4;
    SocketUrl = development;
    explorer = 'https://rinkeby.etherscan.io'
    message = 'Please switch your network to Rinkeby testnet';
    opensea = 'https://testnets.opensea.io/'
    break;
  case 'production':
    networkId = 1;
    SocketUrl = production;
    message = 'Please switch your network to Ethereum Mainnet';
    explorer = 'https://etherscan.io'
    opensea = 'https://opensea.io/'
    break;
  case 'testing':
    networkId = 4;
    SocketUrl = testing;
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    opensea = 'https://testnets.opensea.io/'
    break;
  default:
    networkId = 4;
    SocketUrl = 'http://192.168.18.19:4000';
    message = 'Please switch your network to Rinkeby testnet';
    explorer = 'https://rinkeby.etherscan.io'
    opensea = 'https://testnets.opensea.io/'
}

let ApiUrl = `${SocketUrl}/api`;
export { AppTitle, ApiUrl, SocketUrl, opensea, networkId, message, explorer, env };